import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import { appApi } from "./api/appApi";
import { assetApi } from "./api/assetApi";
import { customFieldApi } from "./api/customFieldApi";
import { facilityApi } from "./api/facilityApi";
import { folderApi } from "./api/folderApi";
import { customFieldTemplateApi } from "./api/gql/customFieldTemplateApi";
import { gqlApi } from "./api/gql/gqlApi";
import { labelApi } from "./api/gql/labelApi";
import { scanApi } from "./api/scanApi";
import { snapshotApi } from "./api/snapshotApi";
import { tenantApi } from "./api/tenantApi";
import { uploadDataApi, splatProgressApi } from "./api/uploadDataApi";
import { userApi } from "./api/userApi";
import appSlice from "./features/appSlice";
import authSlice from "./features/authSlice";

export const store = configureStore({
  reducer: {
    [appApi.reducerPath]: appApi.reducer,
    [gqlApi.reducerPath]: gqlApi.reducer,
    [labelApi.reducerPath]: labelApi.reducer,
    [appSlice.name]: appSlice.reducer,
    [assetApi.reducerPath]: assetApi.reducer,
    [authSlice.name]: authSlice.reducer,
    [customFieldApi.reducerPath]: customFieldApi.reducer,
    // Remove the duplicate property in the object literal
    [customFieldTemplateApi.reducerPath]: customFieldTemplateApi.reducer,
    [facilityApi.reducerPath]: facilityApi.reducer,
    [folderApi.reducerPath]: folderApi.reducer,
    [scanApi.reducerPath]: scanApi.reducer,
    [snapshotApi.reducerPath]: snapshotApi.reducer,
    [tenantApi.reducerPath]: tenantApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [uploadDataApi.reducerPath]: uploadDataApi.reducer,
    [splatProgressApi.reducerPath]: splatProgressApi.reducer,
  },
  middleware: (gDM) =>
    gDM({}).concat([
      appApi.middleware,
      gqlApi.middleware,
      labelApi.middleware,
      assetApi.middleware,
      customFieldApi.middleware,
      customFieldTemplateApi.middleware,
      facilityApi.middleware,
      folderApi.middleware,
      scanApi.middleware,
      snapshotApi.middleware,
      tenantApi.middleware,
      userApi.middleware,
      uploadDataApi.middleware,
      splatProgressApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
