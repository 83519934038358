import { useCallback, useEffect, useState } from "react";
import * as THREE from "three";

import { disposeObject } from "./ObjectLoader";
import { ModelViewerScene } from "./types";
import { addGridAxes } from "components/CameraSelector/Common/ThreeUtils";
import {
  VIEWPORT_ASPECT_RATIO,
  createAmbientLight,
  VIEWPORT_FOV_V,
  RenderLayer,
} from "components/CameraSelector/Constants";
import { CameraMode } from "utils/Contexts/SceneContext";

export const useModelViewerScene = (
  textureEnabled = true,
  cameraMode = CameraMode.Persp,
  floorplanView = false
): ModelViewerScene => {
  const [gridHelper, setGridHelper] = useState<THREE.GridHelper | null>(null);
  const [camera, setCamera] = useState<THREE.OrthographicCamera | THREE.PerspectiveCamera>(
    new THREE.OrthographicCamera(VIEWPORT_ASPECT_RATIO / -2, VIEWPORT_ASPECT_RATIO / 2, 10 / 2, 10 / -2, 0.001, 10000)
  );

  const [scene, setScene] = useState<THREE.Scene>(new THREE.Scene());
  const [contentParent, setContentParent] = useState<THREE.Group>(new THREE.Group());

  useEffect(() => {
    if (floorplanView) {
      const newCam = new THREE.PerspectiveCamera(VIEWPORT_FOV_V, VIEWPORT_ASPECT_RATIO, 0.001, 10000);
      newCam.name = "sceneCamera";
      newCam.layers.enable(RenderLayer.ANNOTATION_LAYER);
      setCamera(newCam);
    } else {
      setCamera((prevCamera) => {
        const cam =
          cameraMode === CameraMode.Persp
            ? new THREE.PerspectiveCamera(VIEWPORT_FOV_V, VIEWPORT_ASPECT_RATIO, 0.001, 10000)
            : new THREE.OrthographicCamera(
                VIEWPORT_ASPECT_RATIO / -2,
                VIEWPORT_ASPECT_RATIO / 2,
                10 / 2,
                10 / -2,
                0.001,
                10000
              );
        cam.name = "sceneCamera";
        cam.position.copy(prevCamera.position);
        cam.rotation.copy(prevCamera.rotation);
        cam.layers.enable(RenderLayer.ANNOTATION_LAYER);
        return cam;
      });
    }
  }, [floorplanView, cameraMode]);

  const resetScene = useCallback(() => {
    const s = new THREE.Scene();
    const ambiLight = createAmbientLight();
    ambiLight.layers.enable(RenderLayer.ANNOTATION_LAYER);
    s.add(ambiLight);
    !floorplanView && setGridHelper(addGridAxes(s));

    const newContentParent = new THREE.Group();
    s.add(newContentParent);

    setScene((prev) => {
      prev.children.forEach((object) => {
        disposeObject(object, prev);
      });
      prev.clear();

      return s;
    });

    setContentParent(newContentParent);
  }, [floorplanView]);

  useEffect(() => {
    resetScene();
  }, [resetScene]);

  return {
    scene: scene,
    camera,
    gridHelper,
    contentParent: contentParent,
    resetScene,
  };
};
