import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";

import { PageData, deserializePageData, QueryRequestParams, buildGqlQueryRequest, generateFilter } from "./gqlUtils";
import graphqlFetchBase from "./graphqlFetchBase";

export interface SearchLabelsParams {
  objectID?: string;
}

export interface LabelParams {
  id?: string;
  name?: string;
  colour?: string;
  description?: string;
}

export interface LabelApplyParams {
  labelId: string;
  objectId: string;
  type: string;
}

export interface LabelsResponse {
  id?: string;
  name?: string;
  colour?: string;
  description?: string;
  relatedItemCount?: number;
}

export interface LabelsAssociation {
  labelId: string;
  name: string;
  associatedObjectIds: string[];
}

export interface LabelsPageData {
  data: LabelsResponse[];
  pageData: PageData;
}

export const deserializeLabels = (label: any) => {
  return {
    ...label,
  } as LabelsResponse;
};

export const deserializeLabelAssociations = (data: any) => {
  const associationDict: { [id: string]: LabelsAssociation } = {};
  data.forEach((association: any) => {
    const labelId = association.label.id;
    if (labelId in associationDict) {
      associationDict[labelId].associatedObjectIds = associationDict[labelId].associatedObjectIds.concat([
        association.objectId,
      ]);
    } else {
      associationDict[labelId] = {
        labelId: labelId,
        name: association.label.name,
        associatedObjectIds: [association.objectId],
      } as LabelsAssociation;
    }
  });
  return Object.values(associationDict);
};

export const defaultLabelsQueryData = `
  id
  name
  description
  colour
  relatedItemCount
  `;

export const labelApi = createApi({
  reducerPath: "labelApi",
  baseQuery: graphqlFetchBase,
  tagTypes: ["associations"],
  endpoints: (builder) => ({
    getLabels: builder.query<LabelsPageData, QueryRequestParams>({
      query: (param: QueryRequestParams) => ({
        document: gql`
          ${buildGqlQueryRequest("labels", param.queryData, param.page, param.sort, param.filters)}
        `,
      }),
      transformResponse: async (response: any) => {
        const labels = response.labels.map((label: any) => {
          return deserializeLabels(label);
        });

        const pageData = deserializePageData(response.pageData.labels);

        return { data: labels, pageData: pageData };
      },
    }),
    addLabel: builder.query<LabelsResponse, LabelParams>({
      query: (param: LabelParams) => ({
        document: gql`
          mutation {
            createLabelItem(data: {description: "${param.description}", name: "${param.name}", colour: "${param.colour}"}) {
            ... on LabelItem {
              id
              name
              description
              colour
              relatedItemCount
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializeLabels(response.createLabelItem);
      },
    }),
    updateLabel: builder.query<LabelsResponse, LabelParams>({
      query: (param: LabelParams) => ({
        document: gql`
          mutation {
            updateLabelItem(data: {id: "${param.id}", description: "${param.description}", name: "${param.name}", colour: "${param.colour}"}) {
              ... on LabelItem {
                id
                name
                description
                colour
                relatedItemCount
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializeLabels(response.updateLabelItem);
      },
    }),
    deleteLabel: builder.query<string, string>({
      query: (id: string) => ({
        document: gql`
          mutation {
            deleteLabelItem(data: { id: "${id}" }) {
              ... on LabelItem {
                id
                colour
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return "Label deleted";
      },
    }),
    applyLabel: builder.query<string, LabelApplyParams>({
      query: (params: LabelApplyParams) => ({
        document: gql`
          mutation {
            applyLabelTo${params.type}(data: {objectId: "${params.objectId}", labelId: "${params.labelId}"})
            {
              objectId
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return "Label applied";
      },
    }),
    removeLabel: builder.query<string, LabelApplyParams>({
      query: (params: LabelApplyParams) => ({
        document: gql`
          mutation {
            removeLabelFrom${params.type}(data: {objectId: "${params.objectId}", labelId: "${params.labelId}"})
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return "Label Removed";
      },
    }),
    getAssociatedLabels: builder.query<LabelsAssociation[], string[]>({
      query: (objectIds: string[]) => ({
        document: gql`
          query {
            labelsAssociation(
              filters: { ${generateFilter({
                object: {
                  inList: objectIds,
                },
              })}}
            ) {
              label {
                name
                id
              }
              objectId
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializeLabelAssociations(response.labelsAssociation);
      },
      providesTags: ["associations"],
    }),
  }),
});

export const {
  useLazyGetLabelsQuery,
  useLazyAddLabelQuery,
  useLazyUpdateLabelQuery,
  useLazyDeleteLabelQuery,
  useLazyApplyLabelQuery,
  useLazyRemoveLabelQuery,
  useGetAssociatedLabelsQuery,
} = labelApi;
